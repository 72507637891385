<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      d="M15.005 0.998718C12.6191 0.998718 10.3308 1.94612 8.64313 3.63267C8.25248 4.02307 8.25227 4.65623 8.64267 5.04689C9.03306 5.43754 9.66623 5.43775 10.0569 5.04735C11.3695 3.73559 13.1493 2.99872 15.005 2.99872C16.8607 2.99872 18.6405 3.73559 19.9531 5.04735C20.3438 5.43775 20.9769 5.43754 21.3673 5.04689C21.7577 4.65623 21.7575 4.02307 21.3669 3.63267C19.6792 1.94612 17.3909 0.998718 15.005 0.998718Z"
    />
    <path
      d="M13.0953 5.37799C13.7023 5.12637 14.3529 4.99686 15.01 4.99686C15.6671 4.99686 16.3177 5.12637 16.9247 5.37799C17.5317 5.62961 18.0831 5.99841 18.5475 6.46329C18.9378 6.85403 18.9374 7.4872 18.5467 7.8775C18.156 8.26781 17.5228 8.26746 17.1325 7.87672C16.8539 7.59779 16.523 7.37651 16.1588 7.22554C15.7946 7.07456 15.4042 6.99686 15.01 6.99686C14.6157 6.99686 14.2254 7.07456 13.8612 7.22554C13.497 7.37651 13.1661 7.59779 12.8875 7.87672C12.4972 8.26746 11.864 8.26781 11.4733 7.8775C11.0825 7.4872 11.0822 6.85403 11.4725 6.46329C11.9369 5.99841 12.4883 5.62961 13.0953 5.37799Z"
    />
    <path
      d="M6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19H6.01C6.56228 19 7.01 18.5523 7.01 18C7.01 17.4477 6.56228 17 6.01 17H6Z"
    />
    <path
      d="M9 18C9 17.4477 9.44771 17 10 17H10.01C10.5623 17 11.01 17.4477 11.01 18C11.01 18.5523 10.5623 19 10.01 19H10C9.44771 19 9 18.5523 9 18Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 10C16 9.44771 15.5523 9 15 9C14.4477 9 14 9.44771 14 10V13H4C2.34315 13 1 14.3431 1 16V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V16C23 14.3431 21.6569 13 20 13H16V10ZM4 15C3.44772 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V16C21 15.4477 20.5523 15 20 15H4Z"
    />
  </svg>
</template>
